<template>
  <div class="thunderstreak-safety">
    雷电安全
  </div>
</template>

<script>
  export default {
    name: 'ThunderstreakSafety'
  }
</script>

<style lang="scss" scoped>
  .thunderstreak-safety {
    width: 1920px;
    height: 300px;
    line-height: 300px;
    text-align: center;
    background-color: #666;
  }
</style>